
 .entry-page h2{
  font-size: 23px;
}
 .entry-page p{
  font-size: 12px;
}

 .entry-page .button a {
    text-decoration: none;
    color: inherit;
}

 .entry-page .main-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    text-align: center;
}

 .entry-page .title-section {
    margin-bottom: 20px;
}

 .entry-page .author img{
  max-width: 20%;
  height: 30px;
  margin-top: 20px;

}

 .entry-page .author span{
  padding-right: 10px;
}

 .entry-page .left-column {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

 .entry-page .left-column img {
    max-width: 500px;
    height: auto;
}

 .entry-page .right-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 100px;
    justify-content: center;
}

label{
  font-weight: bolder;
  font-size: larger;
}
select{
  margin: 20px;
}

 .entry-page .button {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px;
    border-radius: 15px;
    border: gray solid;
    margin: 17px;
    width: 450px; /* Adjust the maximum width to your preference, currently set to same as image on the left */
}

.button:hover{
  color: dodgerblue;
}

 .entry-page .button img {
    max-width: 100px; /* Adjust the maximum width to your preference */
    height: auto;
    margin-left: 10px;
}

 .entry-page .button .button-content{
  display: flex;
  justify-content: space-around;
}

 .entry-page .button.company{
  background-color: black; /* Add background color */
  color: white; /* Text color */
}

.entry-page .button.company:hover{
  color: dodgerblue;
}
